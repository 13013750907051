const timezoneOptions = [
  { label: '(UTC-11:00) Coordinated Universal Time-11', id: 'Pacific/Niue' },
  { label: '(UTC-10:00) Hawaii', id: 'US/Hawaii' },
  { label: '(UTC-09:00) Alaska', id: 'America/Anchorage' },
  { label: '(UTC-08:00) Baja California', id: 'America/Los_Angeles' },
  { label: '(UTC-07:00) Mountain Time (US & Canada)', id: 'America/Boise' },
  { label: '(UTC-06:00) Central Time (US & Canada)', id: 'America/Chicago' },
  { label: '(UTC-05:00) Eastern Time (US & Canada)', id: 'America/New_York' },
  { label: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan', id: 'America/Aruba' },
  { label: '(UTC-03:00) Buenos Aires', id: 'America/Buenos_Aires' },
  { label: '(UTC-02:00) Coordinated Universal Time-02', id: 'Brazil/DeNoronha' },
  { label: '(UTC-01:00) Azores', id: 'Atlantic/Azores' },
  { label: '(UTC) Coordinated Universal Time', id: 'UTC' },
  { label: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', id: 'Europe/Amsterdam' },
  { label: '(UTC+02:00) Athens, Bucharests', id: 'Europe/Athens' },
  { label: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk', id: 'Europe/Moscow' },
  { label: '(UTC+04:00) Port Louis', id: 'Indian/Mahe' },
  { label: '(UTC+05:00) Ashgabat, Tashkent', id: 'Asia/Ashgabat' },
  { label: '(UTC+06:00) Dhaka', id: 'Asia/Dhaka' },
  { label: '(UTC+07:00) Bangkok, Hanoi, Jakarta', id: 'Asia/Bangkok' },
  { label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi', id: 'Asia/Hong_Kong' },
  { label: '(UTC+09:00) Seoul', id: 'Asia/Pyongyang' },
  { label: '(UTC+10:00) Canberra, Melbourne, Sydney', id: 'Australia/Sydney' },
  { label: '(UTC+12:00) Magadan', id: 'Asia/Magadan' },
];

export default timezoneOptions;